define("ember-power-select-typeahead/templates/components/power-select-typeahead/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "scRyOLpj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[11,\"value\",[22,\"text\"]],[10,\"role\",\"combobox\"],[11,\"id\",[29,[\"ember-power-select-typeahead-input-\",[24,[\"select\",\"uniqueId\"]]]]],[10,\"class\",\"ember-power-select-typeahead-input ember-power-select-search-input\"],[10,\"autocomplete\",\"off\"],[10,\"autocorrect\",\"off\"],[10,\"autocapitalize\",\"off\"],[10,\"spellcheck\",\"false\"],[11,\"placeholder\",[22,\"placeholder\"]],[11,\"oninput\",[22,\"onInput\"]],[11,\"onfocus\",[22,\"onFocus\"]],[11,\"onblur\",[22,\"onBlur\"]],[11,\"disabled\",[24,[\"select\",\"disabled\"]]],[11,\"onkeydown\",[28,\"action\",[[23,0,[]],\"handleKeydown\"],null]],[11,\"onmousedown\",[28,\"action\",[[23,0,[]],\"stopPropagation\"],null]],[10,\"type\",\"search\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"select\",\"loading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"ember-power-select-typeahead-loading-indicator\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select-typeahead/templates/components/power-select-typeahead/trigger.hbs"
    }
  });

  _exports.default = _default;
});